/* eslint-disable */
import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';

Swiper.use([Navigation, Pagination]);

const loadSwiper = () => {
  const plansNoTabsSwiper = new Swiper('.swiperPlansStreaming', {
    slidesPerView: 'auto',
    centeredSlides: true,
    spaceBetween: 30,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      type: 'bullets',
    },
    navigation: {
      nextEl: '.Plans-next',
      prevEl: '.Plans-prev',
    },
    breakpoints: {
      420: {
        slidesPerView: 1.3,
      },
      600: {
        slidesPerView: 2,
        slidesPerView: 2,
        spaceBetween: 0,
        centeredSlides: false,
      },
      868: {
        slidesPerView: 2.2,
        spaceBetween: 0,
        centeredSlides: false,
      },
      968: {
        slidesPerView: 2.8,
        spaceBetween: 0,
        centeredSlides: false,
      },
      1168: {
        slidesPerView: 3,
        spaceBetween: 0,
        centeredSlides: false,
      },
    },
    on: {
      init: function () {
        if (this.slides.length <= 3) {
          document
            .querySelector('.swiper-wrapper')
            .classList.add('swiper-wrapper-lock');
        }
      },
    },
  });
};

window.addEventListener('load', loadSwiper);
