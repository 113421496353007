const { ToggleControl } = wp.components;

const ShowBlock = ({ attributes, setAttributes }) => {
  const { showBlock } = attributes;
  return (
    <ToggleControl
      label="Habilitar nas cidades selecionadas"
      checked={showBlock}
      onChange={() => setAttributes({ showBlock: !showBlock })}
    />
  );
};

export default ShowBlock;
