function addTabActive() {
  const tabsTitle = document.querySelectorAll('.TabSumicity__tabs--title');
  const tabsSection = document.querySelectorAll('.TabSumicity__content');

  if (tabsTitle.length && tabsSection.length) {
    let i = 0;

    // Set tab id in its respective section
    while (i < tabsTitle.length) {
      let tabTitleId = tabsTitle[i].getAttribute('id');
      tabsSection[i].setAttribute('id', tabTitleId);
      i++;
    }

    //Set active class in first tab and section
    let firstTab = tabsTitle[0];
    firstTab.classList.add('active');
    firstTab = firstTab.getAttribute('id');
    document
      .querySelector(`.TabSumicity__content[id="${firstTab}"]`)
      .classList.add('active');

    //  Set active class when click in tab
    tabsTitle.forEach((tabTitle) => {
      tabTitle.addEventListener('click', () => {
        cleanActive();
        tabTitle.classList.add('active');
        let tabTitleActiveId = tabTitle.getAttribute('id');
        document
          .querySelector(`.TabSumicity__content[id="${tabTitleActiveId}"]`)
          .classList.add('active');
      });
    });

    // Function to remove all active classes from tab titles
    function cleanActive() {
      tabsTitle.forEach((tabTitle) => {
        tabTitle.classList.remove('active');
      });
      tabsSection.forEach((tabSection) => {
        tabSection.classList.remove('active');
      });
    }
  }
}

window.addEventListener('DOMContentLoaded', addTabActive);

export { addTabActive };
