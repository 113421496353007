import UseNormalizeText from './hooks/UseNormalizeText';

const simulateClick = (elem) => {
  // Create our event (with options)
  var evt = new MouseEvent('click', {
    bubbles: true,
    cancelable: true,
    view: window,
  });
  // If cancelled, don't dispatch our event
  var canceled = !elem.dispatchEvent(evt);
};

const toggleAccordion = (item, status, openItem) => {
  if (openItem) {
    item.setAttribute('open', true);
  } else {
    item.removeAttribute('open');
  }

  if (status) {
    item.classList.remove('Accordion--isInactive');
    item.classList.add('Accordion--isActive');
  } else {
    item.classList.remove('Accordion--isActive');
    item.classList.add('Accordion--isInactive');
  }
};

const toggleTab = (item, showTab, activeTab) => {
  if (item) {
    item.classList.remove('active');

    if (activeTab) {
      item.classList.add('active');
      simulateClick(item);
    }

    if (showTab) {
      item.classList.remove('TabSumicity__tabs--isInactive');
      item.classList.add('TabSumicity__tabs--isActive');
    } else {
      item.classList.remove('TabSumicity__tabs--isActive');
      item.classList.add('TabSumicity__tabs--isInactive');
    }
  }
};

const getContent = (item) => {
  const finalItem = item;

  if (finalItem.length > 1) {
    const texts = [...finalItem].map((n) => n.textContent);

    return texts;
  } else {
    return finalItem.textContent.toLowerCase();
  }
};

const searchInAccordions = (input, inTabs = false) => {
  const params = new URL(window.location).searchParams;
  const searchTerm = params.getAll('searchTerm')[0];

  input.addEventListener('keyup', customSearch);

  if (searchTerm) {
    input.value = searchTerm;
    input.dispatchEvent(new KeyboardEvent('keyup'));
  }

  function customSearch(e) {
    const term = e.target.value.toLowerCase();
    let activeTab = true;
    let openItem = true;

    if (!term) {
      //Ativa todos os itens caso o texto seja vazio
      const allItens = Array.from(document.querySelectorAll('.Accordion'));
      let currentTab = '';

      allItens.map((item) => {
        toggleAccordion(item, true, false);

        if (inTabs) {
          const tabItemId = item
            .closest('.TabSumicity__content')
            .getAttribute('id');

          if (currentTab !== tabItemId) {
            currentTab = tabItemId;

            if (tabItemId) {
              const tabItem = document.querySelector(`#${tabItemId}`);

              toggleTab(tabItem, true, activeTab);
              activeTab = false;
            }
          }
        }
      });
    } else {
      let allParent;
      let allTabItens = ['no-acordion'];

      if (inTabs) {
        allTabItens = Array.from(
          document.querySelectorAll('.TabSumicity__content')
        );
      } else {
        allParent = Array.from(document.querySelectorAll('.Accordion--parent'));
      }

      allTabItens.map((tabItemContent) => {
        let emptyTab = true;
        let tabItemId, tabItem;

        if (inTabs) {
          tabItemId = tabItemContent.getAttribute('id');
          tabItem = document.querySelector(`#${tabItemId}`);

          allParent = Array.from(
            tabItemContent.querySelectorAll('.Accordion--parent')
          );
        }

        allParent.map((parent) => {
          let emptyParent = true;

          const parentTitle = parent
            .querySelector('.Accordion__title')
            .textContent.toLowerCase();

          if (!parentTitle.includes(term) && term) {
            let allIChild = Array.from(
              parent.querySelectorAll('.Accordion--child')
            );

            const checkItem = (item) => {
              let content = '';

              const title = item
                .querySelector('.Accordion__title')
                .textContent.toLowerCase();

              content += title + ' ';

              const allContent = Array.from(
                item.querySelectorAll('.Typography')
              );

              const childList = item.querySelectorAll('li');
              if (childList.length) {
                allContent.push(childList);
              }

              allContent.map((contentItem) => {
                content += getContent(contentItem) + ' ';
              });

              if (content.includes(term)) {
                emptyParent = false;
                emptyTab = false;
                toggleAccordion(item, true);
                toggleAccordion(parent, true);
              } else {
                toggleAccordion(item, false);
              }
            };

            if (!allIChild.length) {
              checkItem(parent);
            } else {
              allIChild.map((item) => {
                checkItem(item);
              });
            }
          } else {
            toggleAccordion(parent, true, openItem);
            const allIChild = Array.from(
              parent.querySelectorAll('.Accordion--child')
            );

            allIChild.map((item) => {
              toggleAccordion(item, true, false);
            });

            emptyParent = false;
            emptyTab = false;
            openItem = false;
          }

          if (emptyParent) {
            toggleAccordion(parent, false);
          }
        });

        if (inTabs) {
          if (emptyTab) {
            toggleTab(tabItem, false, false);
          } else {
            toggleTab(tabItem, true, activeTab);
            activeTab = false;
          }
        }
      });
    }
  }
};

const searchInCities = (input) => {
  input.addEventListener('keyup', customSearch);

  function customSearch(e) {
    const term = UseNormalizeText(e.target.value.toLowerCase());

    const allStates = Array.from(
      document.querySelectorAll('.list-cities .list-cities-item')
    );

    if (!term) {
      allStates.map((state) => {
        const cities = Array.from(state.querySelectorAll('button'));
        cities.map((city) => {
          city.classList.remove('hide');
        });
      });
    } else {
      allStates.map((state) => {
        const cities = Array.from(state.querySelectorAll('button'));
        let count = 0;

        cities.map((city) => {
          const text = UseNormalizeText(city.textContent.toLowerCase());

          if (!text.includes(term) && term) {
            city.classList.add('hide');
          } else {
            count++;
            city.classList.remove('hide');
          }
        });

        if (count === 0) {
          state.classList.add('hide');
        } else {
          state.classList.remove('hide');
        }
      });
    }
  }
};

const customSearchBar = () => {
  const searchAccordionInput = document.querySelector(
    '.custom-search.accordion .custom-search-input'
  );

  const searchCitiesInput = document.querySelector(
    '.custom-search.locations .custom-search-input'
  );

  const searchAccordionTabsInput = document.querySelector(
    '.custom-search.accordion-tabs .custom-search-input'
  );

  // Faz busca nos accordions
  if (searchAccordionInput) {
    searchAccordionInput.removeAttribute('disabled');
    searchInAccordions(searchAccordionInput);
  }

  // Faz busca nos accordions dentro de tabs
  if (searchAccordionTabsInput) {
    searchAccordionTabsInput.removeAttribute('disabled');
    searchInAccordions(searchAccordionTabsInput, true);
  }

  // Faz busca nas cidades
  if (searchCitiesInput) {
    searchCitiesInput.removeAttribute('disabled');
    searchInCities(searchCitiesInput);
  }

  //Impede o form de ser enviado
  const searchForm = document.querySelector('.custom-search form.search-form');
  if (searchForm) {
    searchForm.addEventListener('submit', (event) => {
      event.preventDefault();
    });
  }
};

window.addEventListener('load', customSearchBar);
