window.addEventListener('load', async () => {
  const cpfField = document.getElementById('cpfField');
  const btnModal = document.querySelector('.button-modal');
  const btnSubmit = document.querySelector('.btn-submit');
  const invisibleBtnSubmit = document.querySelector('.invisible-btn-submit');
  const modalContainer = document.querySelector('.modal-container');
  const form = document.querySelector('.form-lp');

  function validaCPF(cpf) {
    cpf = cpf.replace(/\D+/g, '');
    if (cpf.length !== 11) return false;
  
    let soma = 0;
    let resto;
    if (/^(\d)\1{10}$/.test(cpf)) return false; 
  
    for (let i = 1; i <= 9; i++) soma += parseInt(cpf.substring(i-1, i)) * (11 - i);
    resto = (soma * 10) % 11;
    if ((resto === 10) || (resto === 11)) resto = 0;
    if (resto !== parseInt(cpf.substring(9, 10))) return false;
  
    soma = 0;
    for (let i = 1; i <= 10; i++) soma += parseInt(cpf.substring(i-1, i)) * (12 - i);
    resto = (soma * 10) % 11;
    if ((resto === 10) || (resto === 11)) resto = 0;
    if (resto !== parseInt(cpf.substring(10, 11))) return false;
  
    return true;
  }

  if(cpfField != null) {
    cpfField.addEventListener('input', function (e) {
      cpfField.setCustomValidity(''); // Remove error message
      var value = e.target.value;
      var cpfPattern = value.replace(/\D/g, '') 
        .replace(/(\d{3})(\d)/, '$1.$2') 
        .replace(/(\d{3})(\d)/, '$1.$2')
        .replace(/(\d{3})(\d)/, '$1-$2') 
        .replace(/(-\d{2})\d+?$/, '$1'); 
      e.target.value = cpfPattern;
    });
  }

  if(btnModal != null) {
    btnModal.addEventListener('click', () => modalContainer.style.display = "none")
  }

  if(form != null) {
    form.addEventListener('submit', (event) => {
      event.preventDefault();

      if(validaCPF(cpfField.value)){
        cpfField.setCustomValidity(''); // Remove error message
        modalContainer.style.display = "flex";
      } else {
        cpfField.setCustomValidity('CPF inválido. Por favor, insira um CPF válido.'); // Show error message
        cpfField.reportValidity(); // Force show alert
      }
    });
  }

  if(btnSubmit != null) {
    btnSubmit.addEventListener('click', (e) => {
      if(validaCPF(cpfField.value)){
        cpfField.setCustomValidity(''); // Remove error message
        invisibleBtnSubmit.click();
      } else {
        cpfField.setCustomValidity('CPF inválido. Por favor, insira um CPF válido.'); // Show error message
        cpfField.reportValidity(); // Force show alert
      }
    })
  }
})
