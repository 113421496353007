const { ToggleControl } = wp.components;

const HideBlock = ({ attributes, setAttributes }) => {
  const { hideBlock } = attributes;
  return (
    <ToggleControl
      label="Desabilitar nas cidades selecionadas"
      checked={hideBlock}
      onChange={() => setAttributes({ hideBlock: !hideBlock })}
    />
  );
};

export default HideBlock;
