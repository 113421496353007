setTimeout(() => {
  const controllerButton = [].slice.call(
    document.getElementsByClassName('controllerButton')
  );
  const tabs = [].slice.call(document.getElementsByClassName('taball'));
  const tabLinks = [].slice.call(document.getElementsByClassName('tablinks'));
  const planos = [].slice.call(document.getElementsByClassName('planos'));
  const plansDetails = [].slice.call(
    document.getElementsByClassName('plansContainer')
  );

  // Function to activate the plan details
  const exibeDetalhesPlanos = (infoTab) => {
    if (plansDetails.length) {
      plansDetails.forEach((planDetail) => {
        if (infoTab == planDetail.dataset?.plan) {
          planDetail.style.display = 'flex';
        }
      });
    }
  };

  // The controllerButton are the buttons for internet and internet + streaming
  controllerButton.forEach((botao, index) => {
    botao.addEventListener('click', function () {
      controllerButton.forEach((item) => {
        item.classList.remove('active');
      });
      if (botao == this) {
        botao.classList.add('active');
      }
      tabLinks.forEach((item) => {
        item.classList.remove('active');
      });
      tabs.forEach((item) => item.classList.remove('active'));
      tabs[index].classList.add('active');
      planos.forEach((item) => item.classList.remove('active'));
      plansDetails.forEach((planDetail) => (planDetail.style.display = 'none'));

      // If the index is equal to 0, show the plan with just internet
      if (index == 0) {
        tabLinks[0].classList.add('active');
        planos[0].classList.add('active');
      } else {
        // In this case, show the plans with streaming
        tabLinks[1].classList.add('active');
        planos.forEach((item) => {
          if (item?.dataset?.plan == tabLinks[1].dataset?.plan) {
            item.classList.add('active');
          }
        });
        if (tabLinks[1]?.dataset?.plan) {
          exibeDetalhesPlanos(tabLinks[1]?.dataset?.plan);
        }
      }
    });
  });

  tabLinks.forEach((tab, index) => {
    // Check if the active tab link initially contains a plan detail; activate it if it does
    if (tab.classList.contains('active') && tab?.dataset?.plan) {
      exibeDetalhesPlanos(tab?.dataset?.plan);
    }

    tab.addEventListener('click', function () {
      tabLinks.forEach((item) => item.classList.remove('active'));
      this.classList.add('active');
      planos.forEach((item) => item.classList.remove('active'));

      // Select the correct plan
      if (index == 0 && controllerButton.length > 0) {
        planos[0].classList.add('active');
      } else {
        planos.forEach((item) => {
          if (item?.dataset?.plan == tab?.dataset?.plan) {
            item.classList.add('active');
          }
        });
      }

      // Select the correct plan details
      plansDetails?.forEach(
        (planDetail) => (planDetail.style.display = 'none')
      );
      if (tab?.dataset?.plan) {
        exibeDetalhesPlanos(tab?.dataset?.plan);
      }

      // Change the src of images based on the 'active' class
      tabLinks.forEach((link) => {
        const img = link.querySelector('img'); // Selects the image of the tab link
        if (img) {
          const srcFocus = img.dataset.srcFocus; // Gets the value of data-src-focus
          const src = img.dataset.src; // Gets the value of data-src

          if (link.classList.contains('active')) {
            // Check if srcFocus has a value before assigning
            if (srcFocus) {
              img.src = srcFocus; // Changes to data-src-focus if it exists
            }
          } else {
            // Check if src has a value before assigning
            if (src) {
              img.src = src; // Changes to data-src if it exists
            }
          }
        }
      });
    });
  });
}, 2000);