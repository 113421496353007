/* eslint-disable */
import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';

Swiper.use([Navigation, Pagination]);

const loadSwiperAboutUs = () => {
  if (document.body.getBoundingClientRect().width < 1024) {
    const swiper = new Swiper('.about-us-cards-swiper', {
      slidesPerView: 1,
      spaceBetween: 30,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        type: 'bullets',
      },
      navigation: {
        nextEl: '.swiper-button-next-unique',
        prevEl: '.swiper-button-prev-unique',
      },
      breakpoints: {
        480: {
          slidesPerView: 1,
        },
        768: {
          slidesPerView: 3,
        },
        968: {
          slidesPerView: 4,
        },
      },
    });
  }
};

window.addEventListener('load', loadSwiperAboutUs);
