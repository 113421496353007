window.addEventListener('DOMContentLoaded', () => {
  const allSeeMore = document.querySelectorAll('.see-more-local');
  const seeMoreButton = document.querySelector('.local-see-more p');

  if(seeMoreButton != null) {
    const seeMoreButtonText = seeMoreButton.innerHTML;

    seeMoreButton.addEventListener('click', () => {
      if (!seeMoreButton.classList.contains('opened')) {
        allSeeMore.forEach((item) => (item.style.display = 'block'));
        seeMoreButton.classList.add('opened');
        seeMoreButton.innerHTML = 'Ver menos';
      } else {
        allSeeMore.forEach((item) => (item.style.display = 'none'));
        seeMoreButton.classList.remove('opened');
        seeMoreButton.innerHTML = seeMoreButtonText;
      }
    });
  }
});
