import Inspector from './inspector';

// Remover caso não seja utilizado
const { InnerBlocks } = wp.blockEditor;

const Edit = ({ attributes, setAttributes }) => {
  const { variant, margin, imageMobile, imageDesk } = attributes;

  const ALLOWED_BLOCKS = [
    'base/heading-sumicity',
    'base/paragraph-sumicity',
    'base/button-sumicity',
  ];

  return (
    <>
      <Inspector attributes={attributes} setAttributes={setAttributes} />

      <section className={`banner-text-cta-image ${variant} ${margin}`}>
        <div className='gradient gradient-top'></div>

        <img className='image desktop' src={imageDesk.src}/>
        <img className='image mobile' src={imageMobile.src}/>

        <InnerBlocks allowedBlocks={ALLOWED_BLOCKS} />

        <div className='gradient gradient-bottom'></div>
      </section>
    </>
  );
};

export default Edit;