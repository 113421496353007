/* eslint-disable */
import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';

const loadSwiper = () => {
  const advantageSwiper = document.querySelector('.Advantage-swiper');

  if (advantageSwiper) {
    Swiper.use([Navigation, Pagination]);

    const swiper = new Swiper('.Advantage-swiper', {
      slidesPerView: 1,
      spaceBetween: 16,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        type: 'bullets',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        768: {
          slidesPerView: 3,
        },
        1024: {
          allowTouchMove: false,
          slidesPerView: 'auto',
        },
      },
    });
  }
};

window.addEventListener('load', loadSwiper);
