document.addEventListener('DOMContentLoaded', () => {
  var forms = document.querySelectorAll('.rd-integration-form');
  var inputsPreenchido = 0;

  if (forms) {
    forms.forEach((form) => {
      var submitButton = form.querySelector('input[type="submit"]');
      var inputs = form.querySelectorAll('.rd-form-input');

      submitButton.addEventListener('click', () => {
        for (var i = 0; i < inputs.length; i++) {
          if (inputs[i].value) {
            inputsPreenchido++;
          }
        }

        if (inputsPreenchido == inputs.length) {
          const redirectLink = form.getAttribute('data-redirect');
          form.submit(function () {
            return false;
          });

          // verificar necessidade desta função.
          // Atualmente não chega aqui.
          if (redirectLink) {
            const redirectTarget = form.getAttribute('data-target');
            if (redirectTarget === 'blank') {
              window.open(redirectLink, '_blank');
            } else {
              window.location.href = redirectLink;
            }
          }
        }

        inputsPreenchido = 0;
      });
    });
  }
});
