import UseNormalizeText from './hooks/UseNormalizeText';
import UseGetData from './hooks/UseGetData';

const setCity = (text, cities) => {
  let selectedCity = cities.filter((city) => {
    if (UseNormalizeText(city.address_city) === text) {
      return city;
    }
  });

  if (selectedCity[0] === undefined) {
    selectedCity = [
      {
        address_city: 'Endereço não encontrado',
      },
    ];
  }

  selectedCity = selectedCity[0];

  let content = '';

  if (selectedCity.address_city)
    content += `<p class="city">${selectedCity.address_city}</p>`;

  if (selectedCity.adress_street)
    content += `<p class="address">${selectedCity.adress_street}</p>`;

  if (selectedCity.adress_phone)
    content += ` <a href="tel:${selectedCity.adress_phone}" class="tel">${selectedCity.adress_phone}</a>`;

  if (selectedCity.adress_opening)
    content += `<p class="schedules">${selectedCity.adress_opening}</p>`;

  if (selectedCity.adress_url)
    content += ` <a href="${selectedCity.adress_url}" target="blank" class="gmaps">Abrir no Google Maps</a>`;

  const CardLocate = document.querySelector(
    '.Card-col-item.locate .Card-col-item-text'
  );

  CardLocate.innerHTML = content;
};

const selectCity = async () => {
  const allCities = Array.from(
    document.querySelectorAll('.list-cities button')
  );

  if (allCities.length) {
    const response = await UseGetData('acf/v2/options/address_group');
    const cities = response?.address_group || [];
    let firstClick = true;

    allCities.forEach((cityButton) => {
      cityButton.addEventListener('click', (e) => {
        allCities.map((city) => {
          city.classList.remove('active');
        });

        cityButton.classList.add('active');

        const text = UseNormalizeText(cityButton.textContent.toLowerCase());

        setCity(text, cities);
      });

      if (firstClick) {
        const text = UseNormalizeText(cityButton.textContent.toLowerCase());
        setCity(text, cities);

        firstClick = false;
      }
    });
  }
};

window.addEventListener('load', selectCity);
