function hidePlans(plans) {
  plans.forEach((plan) => {
    plan.style.display = 'none';
  });
}

function handleTabs(currentTabContent, planId) {
  const mainTab = currentTabContent.querySelector(`.tablinks#${planId}`);
  const activeTab = currentTabContent.querySelector(`.tablinks.active`);

  if (activeTab) activeTab.classList.remove('active');
  if (mainTab) mainTab.classList.add('active');
}

function showPlan(buttonTarget) {
  const currentTabContent = document.querySelector(
    `.Tab-content#${buttonTarget.id}`
  );
  if (!currentTabContent) return;

  const planId = currentTabContent.getAttribute('data-plans-id');

  if (planId) {
    const plansToShow = currentTabContent.querySelectorAll(`div#${planId}`);
    const plansWrap = currentTabContent.querySelectorAll('.Plans-wrap');

    hidePlans(plansWrap);
    handleTabs(currentTabContent, planId);

    plansToShow.forEach((plan) => {
      plan.style.display = 'flex';
    });
  }
}

export { showPlan };
