import { verifyContent } from './hideTab';
import { initEditorTabs } from './handleEditor';
import { handleURL } from './urlHandler';
import { handleTabClick } from './tabHandler';

function initTabs() {
  if (window.location.pathname.match(/wp-admin.+/)) return initEditorTabs();
  verifyContent();

  const tabs = document.querySelectorAll('.pagetab');
  handleURL();

  tabs.forEach((tab) => {
    tab.addEventListener('click', (event) => handleTabClick(event, tabs));
  });
}

window.addEventListener('DOMContentLoaded', initTabs);
window.onload = () => initTabs();
