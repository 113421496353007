const { InspectorControls } = wp.blockEditor;
const { PanelBody, SelectControl } = wp.components;
import ImageControl from '../wp-controlers/mediaUpload';
import CommonPanel from '../wp-controlers/common-panel';

const Inspector = ({ attributes, setAttributes }) => {
  const { variant } = attributes;

  return (
    <InspectorControls>
      <PanelBody title="Atributos do Bloco" initialOpen={true}>
        <SelectControl
          label="Selecione o estilo desejado"
          title="Selecione o estilo desejado"
          value={variant}
          options={[
            {
              label: 'Default',
              value: 'default',
            },
          ]}
          onChange={(position) => setAttributes({ variant: position })}
        />
      </PanelBody>

      <PanelBody title="Imagem de Background Mobile" initialOpen={true}>
        <ImageControl
          image="imageMobile"
          attributes={attributes}
          setAttributes={setAttributes}
        />
      </PanelBody>

      <PanelBody title="Imagem de Background Desktop" initialOpen={true}>
        <ImageControl
          image="imageDesk"
          attributes={attributes}
          setAttributes={setAttributes}
        />
      </PanelBody>

      <CommonPanel attributes={attributes} setAttributes={setAttributes} />
    </InspectorControls>
  );
};

export default Inspector;