import { showContent, toggleActiveTab } from './contentHandler';
import { showPlan } from './plansHandler';
import { watchTabsChanges } from './tabHandler';

function handleFirstAcess(tabs) {
  const firstTab = tabs[0];
  if (!firstTab) return;

  firstTab.classList.add('active');
  showContent(firstTab.id);
  showPlan(firstTab);
}

function handleEditorTabClick(event, tabs) {
  const { target } = event;

  toggleActiveTab(target, tabs);
  showContent(target.id);
  showPlan(target);
}

function handleEditorTabs() {
  const tabs = document.querySelectorAll('.pagetab');
  handleFirstAcess(tabs);

  tabs.forEach((tab) => {
    tab.addEventListener('click', (event) => handleEditorTabClick(event, tabs));
  });
}

function initEditorTabs() {
  handleEditorTabs();
  watchTabsChanges();
}

export { initEditorTabs, handleEditorTabClick };
