const { InnerBlocks } = wp.blockEditor;

const Save = ({ attributes }) => {
  const { variant, margin, imageMobile, imageDesk } = attributes;

  return (
    <section className={`banner-text-cta-image ${variant} ${margin}`}>
      <div className='gradient gradient-top'></div>

      <img
        src={imageDesk.src}
        alt={imageDesk.alt}
        title={imageDesk.alt}
        width={imageDesk.width}
        height={imageDesk.height}
        className="image desktop"
      />
      <img
        src={imageMobile.src}
        alt={imageMobile.alt}
        title={imageMobile.alt}
        width={imageMobile.width}
        height={imageMobile.height}
        className="image mobile"
      />

      <InnerBlocks.Content />

      <div className='gradient gradient-bottom'></div>
    </section>
  );
};

export default Save;