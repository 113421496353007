/* eslint-disable */
import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';

Swiper.use([Navigation, Pagination]);

const loadSwipers = () => {
  // Instância do swiper para internet em casa
  const internetSwiper = new Swiper('.swiperInternetPlansGigaTv', {
    centeredSlides: true,
    spaceBetween: 30,

    navigation: {
      nextEl: '.PlansGigaTv-internet-next',
      prevEl: '.PlansGigaTv-internet-prev',
    },
    breakpoints: {
      420: {
        slidesPerView: 1.3,
      },
      600: {
        slidesPerView: 2,
        spaceBetween: 0,
        centeredSlides: false,
      },
      868: {
        slidesPerView: 2.2,
        spaceBetween: 0,
        centeredSlides: false,
      },
      968: {
        slidesPerView: 2.8,
        spaceBetween: 0,
        centeredSlides: false,
      },
      1168: {
        slidesPerView: 3,
        spaceBetween: 0,
        centeredSlides: false,
        pagination: {
          el: '.giga-swiper-pagination',
          clickable: true,
          type: 'bullets',
        },
      },
    },
    on: {
      init: function () {
        if (this.slides.length <= 3) {
          document
            .querySelector('.swiper-wrapper')
            .classList.add('swiper-wrapper-lock');
        }
      },
    },
  });

  // Planos Giga Tv
  document
    .querySelectorAll('.swiperPlansAllGigaTv')
    .forEach((swiperElement, index) => {
      new Swiper(`.swiperPlansGigaTv-${index}`, {
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 30,

        navigation: {
          nextEl: `.PlansGigaTv-next-${index}`,
          prevEl: `.PlansGigaTv-prev-${index}`,
        },
        breakpoints: {
          420: {
            slidesPerView: 1.3,
          },
          600: {
            slidesPerView: 2,
            spaceBetween: 0,
            centeredSlides: false,
          },
          868: {
            slidesPerView: 2.2,
            spaceBetween: 0,
            centeredSlides: false,
          },
          968: {
            slidesPerView: 2.8,
            spaceBetween: 0,
            centeredSlides: false,
          },
          1168: {
            slidesPerView: 3,
            spaceBetween: 0,
            centeredSlides: false,
            pagination: {
              el: `.plansTv-pagination-${index}`,
              clickable: true,
              type: 'bullets',
            },
          },
        },
        on: {
          init: function () {
            if (this.slides.length <= 3) {
              this.wrapperEl.classList.add('swiper-wrapper-lock');
            }
          },
        },
      });
    });
};

setTimeout(loadSwipers, 4000);
