// Swiper demos: https://swiperjs.com/demos#default

import Swiper, { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';

const loadSwiperGeral = () => {
  // Get swipper attributes from wordpress panel
  let hasLoop = false;
  let isCentered = false;

  const slideWrapper = document.querySelector(
    '.SwiperGeral__slider .swiper-wrapper'
  );

  if (slideWrapper) {
    Swiper.use([Navigation, Pagination, Autoplay]);

    const SlideItens = slideWrapper.children;
    const SlideQtde = SlideItens.length;

    const getAttributes = document.querySelector('.SwiperGeral__slider');
    let itemSpace = parseInt(getAttributes.getAttribute('card-space'));
    let perView = getAttributes.getAttribute('perview');
    let perView768 = getAttributes.getAttribute('perview768');
    let perView1280 = getAttributes.getAttribute('perview1280');
    let perView1580 = getAttributes.getAttribute('perview1580');
    let playTime = getAttributes.getAttribute('playTime');
    const autoPlayConf = playTime == '' ? {} : {
      autoplay: {
        delay: playTime * 1000,
        disableOnInteraction: false,
      }
    }
    
    let i = 0;
    while (i < SlideQtde) {
      SlideItens[i].classList.add('swiper-slide');
      i++;
    }

    if (isNaN(itemSpace)) {
      itemSpace = 35;
    }

    if (isNaN(perView)) {
      perView = 1;
      perView768 = 2;
      perView1280 = 3;
      perView1580 = 3;
    }

    if (getAttributes.classList.contains('hasLoop')) {
      hasLoop = true;
    }
    if (getAttributes.classList.contains('isCentered')) {
      isCentered = true;
    }

    // Load Swiper
    const GeralSwiper = new Swiper('.SwiperGeral__slider', {
      slidesPerView: perView,
      spaceBetween: itemSpace / 1.5,
      grabCursor: true,
      centeredSlides: SlideQtde < 3 ? false : isCentered,
      loop: hasLoop,
      ...autoPlayConf,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.custom-swiper-next',
        prevEl: '.custom-swiper-prev',
      },
      breakpoints: {
        768: {
          spaceBetween: itemSpace,
          slidesPerView: perView768,
        },
        1280: {
          spaceBetween: 24,
          slidesPerView: perView1280,
        },
        1580: {
          spaceBetween: 24,
          slidesPerView: perView1580,
        },
      },
      on: {
        init: function () {
          if (this.slides.length <= 3) {
            slideWrapper.classList.add('swiper-wrapper-lock');
          }
        },
      },
    });
  }
};

window.addEventListener('load', loadSwiperGeral);
