const customTablePagination = () => {
    const tables = Array.from(document.querySelectorAll(".customTable.hasPagination"));
    
    tables.forEach(table => {
        if (!table) {
            return;
        }

        const isPaginationActive = checkPaginationWidth(table);
        const columns = table.getAttribute("columns");
        const paginationDiv = table.nextElementSibling.classList.contains("customTable-pagination") ? table.nextElementSibling : null;
        
        if (!columns || !paginationDiv) {
            return;
        }

        if (!paginationDiv.hasAttribute("load")) {
            paginationDiv.style.display = "flex";
            // Create dot to each column
            for (let i = 0; i < columns - 1; i++) {
                const dot = document.createElement("div");
                dot.classList.add("dot");

                if (i == 0) {
                    dot.classList.add("active");
                }

                dot.setAttribute("index", i);
                paginationDiv.appendChild(dot);
            };

            // Create dot events
            const dots = Array.from(paginationDiv.children);

            dots.forEach(dot => {
                dot.addEventListener("click", () => {
                    // If is active dot return
                    if (dot.classList.contains("active")) {
                        return;
                    }

                    const index = dot.getAttribute("index");
                    const pageWidth = table.scrollWidth / dots.length;
                    table.scrollTo({
                        left: pageWidth * index,
                        behavior: 'smooth'
                    });
                });
            });

            // Create table event
            const updatePagination = () => {
                const scrollLeft = table.scrollLeft;
                const scrollWidth = table.scrollWidth;
                const containerWidth = table.clientWidth;

                const totalPages = dots.length;
                 // Actual width available for scrolling (ignoring visible container)
                const scrollableWidth = scrollWidth - containerWidth;

                // Calculate scrolling progress in percentage
                const scrollPercentage = scrollLeft / scrollableWidth;

                const currentPage = Math.round(scrollPercentage * (totalPages - 1));

                dots.forEach(dot => {
                    dot.classList.remove("active");
                });

                if (dots[currentPage]){
                    dots[currentPage].classList.add("active");
                }
            };

            table.addEventListener('scroll', updatePagination);
            paginationDiv.setAttribute("load", "true");
        } 
        
        if (isPaginationActive) {
            paginationDiv.style.display = "flex";
        } else {
            paginationDiv.style.display = "none";
        }
    });

}
window.addEventListener("resize", customTablePagination);
window.addEventListener('load', customTablePagination);

const checkPaginationWidth = (table) => {
    return table.scrollWidth >= window.innerWidth;
}

