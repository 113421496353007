document.addEventListener('DOMContentLoaded', () => {
  const phone = document.querySelectorAll('input[type="tel"]');
  const cpf = document.querySelectorAll('input.cpf-mask');

  //Aciona mascara para cpf
  if (cpf) {
    function maskCPF(object, mask) {
      obj = object;
      masc = mask;
      setTimeout(execMaskCPF(), 1);
    }

    function execMaskCPF() {
      obj.value = masc(obj.value);
    }

    function mCPF(cpf) {
      cpf = cpf.replace(/\D/g, '');
      cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
      cpf = cpf.replace(/(\d{3})(\d)/, '$1.$2');
      cpf = cpf.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
      return cpf;
    }

    cpf.forEach((element) => {
      element.addEventListener('keyup', (e) => {
        maskCPF(element, mCPF);
      });
    });
  }

  //Aciona mascara para telefone
  if (phone) {
    function execMask() {
      v_obj.value = v_fun(v_obj.value);
    }

    function mtel(v) {
      v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
      v = v.replace(/^(\d{2})(\d)/g, '($1) $2'); //Coloca parênteses em volta dos dois primeiros dígitos
      v = v.replace(/(\d)(\d{4})$/, '$1-$2'); //Coloca hífen entre o quarto e o quinto dígitos
      return v;
    }

    function mask(o, f) {
      v_obj = o;
      v_fun = f;
      setTimeout(execMask(), 1);
    }

    phone.forEach((element) => {
      element.addEventListener('keyup', (e) => {
        mask(element, mtel);
      });
    });
  }
});
