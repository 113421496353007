export default {
  margin: {
    type: 'string',
    default: 'margin-section-base',
  },
  hideBlock: {
    type: 'boolean',
    default: false,
  },
  showBlock: {
    type: 'boolean',
    default: false,
  },
};

/* =========== Componentes em JS que usam esses atributos ============
 
- base/container
- about-us-cards
- accordion
- advantages
- card
- card-legado
- cards-dropdown
- color-cards
- contact-us
- cta-text
- featured
- form-hero
- form-section
- grid-buttons
- grid-cards
- hero
- image-with-topics
- info
- middle-banner
- plans-api
- plans-company
- plans-corporate
- plans-no-tabs
- plans-tabs
- search-bar
- section-map
- self-service
- short-form
- solution-section
- streaming-content
- swiper
- tabs
- tabs-company
- topics
- you-choose


=========== Componentes em PHP (src/Controller/Blocks) que usam esses atributos ============

- FormHero
- Plans
- PlansCompany
- PlansNoTabs
- PlansTabs
- ShortForm

*/
