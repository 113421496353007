/* eslint-disable */
import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';

Swiper.use([Navigation, Pagination]);

const loadSwiper = () => {
  const plansTabsSwiper = new Swiper('.swiperPlansTabs', {
    slidesPerView: 1,
    spaceBetween: 0,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      type: 'bullets',
    },
    navigation: {
      nextEl: '.Plans-next',
      prevEl: '.Plans-prev',
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
      1170: {
        slidesPerView: 3,
      },
    },
    on: {
      init: function () {
        if (this.slides.length <= 3) {
          document
            .querySelector('.swiper-wrapper')
            .classList.add('swiper-wrapper-lock');
        }
      },
    },
  });
};

window.addEventListener('load', loadSwiper);
