function toggleActiveTab(target, tabs) {
  tabs.forEach((tab) => {
    tab.classList.remove('active');
  });

  target.classList.add('active');
}

function hideOldTab() {
  const tabs = document.querySelectorAll('.Tab-content');

  tabs.forEach((tab) => {
    tab.classList.remove('active');
  });
}

function showContent(targetButtonId) {
  const tabToShow = document.querySelector(
    `.Tab-content[id="${targetButtonId}"]`
  );
  if (!tabToShow) return;

  hideOldTab();

  tabToShow.classList.add('active');
}

export { showContent, toggleActiveTab };
