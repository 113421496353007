async function getChannels(packageName = '') {
  const response = await fetch(
    `/wp-json/sc/v1/channels?package=${packageName}`
  );

  return response.json();
}

async function getCategory() {
  const response = await fetch(
    '/wp-json/wp/v2/channel_category?filter[orderby]=channel_category_order&order=desc&per_page=100'
  );
  return response.json();
}

async function getBundles() {
  const response = await fetch('/wp-json/wp/v2/channel_bundle');
  return response.json();
}

function renderChannel(channel, namePlan) {
  return `
    ${
      channel.bundles.some(
        (bundle) =>
          bundle.name.toLowerCase().replaceAll(' ', '-') ===
          namePlan.toLowerCase().replaceAll(' ', '-')
      )
        ? `
    <div class="modal-content-channel">
        <div class="is-has-hd">
            <h4 class="is-has-hd-text" style="display:${
              channel.is_hd ? 'block' : 'none'
            }">HD</h4>
        </div>
        <img class="modal-content-channel-image" src="${
          channel.image_url ? channel.image_url : ''
        }" title="${channel.title}" alt="${channel.title}">
        <span class="modal-content-channel-title">${channel.title}</span>
    </div>`
        : ``
    }
    `;
}

function renderChannels(channels, category, namePlan) {
  if (!channels.length) {
    return;
  }
  return `
    <h2 class="modal-content-title">${
      category.name + '<i class="icon icon-keyboard_arrow_down"> </i>'
    }  </h2>
    <div class="modal-channel">
        ${channels
          .map((channel) => {
            return renderChannel(channel, namePlan);
          })
          .join('')}
    </div>`;
}

export async function renderModal(namePlan) {
  const plansComboTv = document.getElementById('plansComboTv');

  if (plansComboTv) {
    const categories = await getCategory();
    const channels = await getChannels(namePlan);

    categories.sort((after, before) => {
      if (after.order < before.order) {
        return -1;
      }

      if (after.order > before.order) {
        return 1;
      }

      return 0;
    });

    const channelPackage = document.querySelectorAll('.modal-channel-package');

    if (channelPackage.length) {
      const htmlChannels = categories.map((category) => {
        const channelsByCategory = channels.filter(
          (channel) => channel.category.id === category.id
        );

        return renderChannels(channelsByCategory, category, namePlan);
      });
      channelPackage.forEach((item) => {
        item.innerHTML = htmlChannels.join('');
      });
    }
  }
}
