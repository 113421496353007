function getElements(planId) {
  const tabContent = document.querySelector(`[data-plans-id="${planId}"]`);
  const tabId = tabContent.getAttribute('id');
  const tabs = document.querySelector('.Tab');
  const tab = tabs.querySelector(`#${tabId}`);

  return { tabContent, tabId, tabs, tab };
}

function showTab(planId) {
  const { tab } = getElements(planId);

  tab.style.display = 'inline-block';
}

function hideTab(planId) {
  const { tabContent, tab } = getElements(planId);

  tabContent.remove();
  tab.style.display = 'none';
}

function verifyContent() {
  const tabs = document.querySelectorAll('.pagetab');

  tabs.forEach((tab) => {
    const content = document.querySelector(`.Tab-content#${tab.id}`);
    if (!content) return;
    const plan = content.querySelector('.Plans-wrap.tabcontent');
    if (!plan) return (tab.style.display = 'inline-block');

    const slidesLenght = plan.querySelectorAll('.swiper-slide').length;
    const planId = plan.getAttribute('id');

    if (slidesLenght < 1) return hideTab(planId);

    showTab(planId);
  });
}

export { verifyContent };
