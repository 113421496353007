const { registerBlockType } = wp.blocks;

import attributes from './attributes';
import Edit from './edit';
import Save from './save';

registerBlockType('app/banner-text-cta-image', {
  title: 'Banner com imagem, título, descrição e cta',
  description: 'Banner com imagem, título, descrição e cta',
  icon: 'layout',
  attributes,

  edit: (props) => <Edit {...props} />,
  save: (props) => <Save {...props} />,
});
