
const ativaModal = () => {
  // Funcionamento do modal
  const modalButton = [].slice.call(document.getElementsByClassName('canais-button'))
  const modalCanais = [].slice.call(document.getElementsByClassName('channel-modal-wrapper'))


  const channelsType1 = modalCanais[0].querySelectorAll('.channel-type')

  channelsType1?.forEach((channelType) => {
    channelType.addEventListener('click', function () {
      if (channelType.classList.contains('open')) {
        channelType.classList.remove('open')
      } else {
        channelType.classList.add('open')
        channelsType1.forEach((item) => {
          if (item !== channelType) {
            item.classList.remove('open')
          }
        })
      }
    })
  });

  const channelsType2 = modalCanais[1].querySelectorAll('.channel-type')

  channelsType2?.forEach((channelType) => {
    channelType.addEventListener('click', function () {
      if (channelType.classList.contains('open')) {
        channelType.classList.remove('open')
      } else {
        channelType.classList.add('open')
        channelsType2.forEach((item) => {
          if (item !== channelType) {
            item.classList.remove('open')
          }
        })
      }
    })
  });

  const channelsType3 = modalCanais[2].querySelectorAll('.channel-type')

  channelsType3?.forEach((channelType) => {
    channelType.addEventListener('click', function () {
      if (channelType.classList.contains('open')) {
        channelType.classList.remove('open')
      } else {
        channelType.classList.add('open')
        channelsType3.forEach((item) => {
          if (item !== channelType) {
            item.classList.remove('open')
          }
        })
      }
    })
  });


  modalButton?.forEach((botao) => {
    botao.addEventListener('click', function () {
      // Acões quando o botão de mostrar mais canais é clicado

      // Remove o active de todos os modais
      modalCanais.forEach((item) => {
        item.classList.remove('active')
      })

      // Adiciona active no modal correspondente ao botão clicado
      modalCanais.forEach((item) => {
        if (item.dataset.plan == this.dataset.plan)
          item.classList.add('active')
      })

      // Seleciona apenas o modal que está aberto
      let modalAtivo = document.querySelector('.channel-modal-wrapper.active')

      // Ativa o botão de fechar para o modal que está aberto
      const closeIcon = modalAtivo.querySelector('.close-icon');
      closeIcon.addEventListener('click', () => {
        modalAtivo.classList.remove('active')
      });
    })
  })
}

setTimeout(ativaModal, 4000);