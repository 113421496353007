export default function openCompanyTab(evt, tab) {
  const tabElement = document.getElementById(tab);

  let i;
  let tabcontent;
  let tablinks;

  tabcontent = document.getElementsByClassName('TabsCompany__container');
  for (i = 0; i < tabcontent.length; i++) {
    tabcontent[i].style.display = 'none';
  }

  tablinks = document.getElementsByClassName('TabsCompany__link');
  for (i = 0; i < tablinks.length; i++) {
    tablinks[i].className = tablinks[i].className.replace(' active', '');
  }

  if (tabElement) {
    tabElement.style.display = 'block';
  }

  evt.currentTarget.className += ' active';
}

window.addEventListener('load', () => {
  const tabDeafult = document.getElementById('tabDefaultCompany');

  if (tabDeafult) {
    tabDeafult.click();
  }
});

window.openCompanyTab = openCompanyTab;
