/* eslint-disable */
const loadDrop = () => {
  var accItem = document.getElementsByClassName('CardsDropdown-accordion-item');

  if (accItem) {
    var accHD = document.getElementsByClassName(
      'CardsDropdown-accordion-item-title'
    );

    for (i = 0; i < accHD.length; i++) {
      accHD[i].addEventListener('click', toggleItem, false);
    }

    function toggleItem() {
      var itemClass = this.parentNode.className;
      for (i = 0; i < accItem.length; i++) {
        accItem[i].className = 'CardsDropdown-accordion-item';
      }
      if (itemClass == 'CardsDropdown-accordion-item') {
        this.parentNode.className =
          'CardsDropdown-accordion-item CardsDropdown-accordion-item--isActive';
      }
    }
  }
};

window.addEventListener('load', loadDrop);
