import { showContent, toggleActiveTab } from './contentHandler';
import { showPlan } from './plansHandler';

const urlParams = new URLSearchParams(window.location.search);

function handleFirstAccess() {
  const tabs = document.querySelectorAll('.pagetab');
  const firstTab = tabs[0];
  if (!firstTab) return;

  firstTab.classList.add('active');
  showContent(firstTab.id);
  showPlan(firstTab);
}

function handleURL() {
  if (!urlParams.get('tag')) {
    handleFirstAccess();
    return;
  }

  const currentTab = urlParams.get('tag');
  const tabs = document.querySelectorAll('.pagetab');
  const target = document.querySelector(`button#${currentTab}`);

  toggleActiveTab(target, tabs);
  showContent(target.id);
  showPlan(target);
}

function updateURL(target) {
  const useId = target.getAttribute('data-url-id');

  if (useId === 'false') {
    urlParams.delete('tag');
    window.location.search = urlParams;

    return;
  }

  urlParams.set('tag', target.id);
  window.location.search = urlParams;
}

export { handleURL, updateURL };
