const { SelectControl } = wp.components;

const SelectMargin = ({ attributes, setAttributes }) => {
  return (
    <SelectControl
      label="Margem abaixo do elemento: (mobile - desktop)"
      title="Margem abaixo do elemento: (mobile - desktop)"
      value={attributes.margin}
      options={[
        { label: 'Nenhuma', value: 'margin-none' },
        { label: 'Ultra Small (2px - 8px)', value: 'margin-ultra-small' },
        { label: 'Extra Small (4px - 16px)', value: 'margin-extra-small' },
        {
          label: 'Extra Small 2 (4px - 4px)',
          value: 'margin-extra-small-equal',
        },
        {
          label: 'Extra Small medium (6px - 12px)',
          value: 'margin-extra-small-medium',
        },
        { label: 'Small (12px - 24px)', value: 'margin-small' },
        { label: 'Base (16px - 32px)', value: 'margin-base' },
        { label: 'Medium (24px - 42px)', value: 'margin-medium' },
        { label: 'Large (32px - 64px)', value: 'margin-large' },
        { label: 'Huge (42px - 96px)', value: 'margin-huge' },
        { label: 'Section Small (32px - 32px)', value: 'margin-section-small' },
        { label: 'Section Base (64px - 64px)', value: 'margin-section-base' },
        { label: 'Section Large (96px - 96px)', value: 'margin-section-large' },
        {
          label: 'H1 home margin (14px - 0px)',
          value: 'margin-section-home-h1',
        },
      ]}
      onChange={(position) => setAttributes({ margin: position })}
    />
  );
};

export default SelectMargin;
