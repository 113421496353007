const UseGetData = async (route = 'wp/v2') => {
  const response = await fetch(`${process.env.WP_ENDPOINT}/${route}`)
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      console.error('Error:', error);
    });

  if (response.code === 'rest_no_route') {
    return null;
  } else {
    return response;
  }
};

export default UseGetData;
