import { updateURL } from './urlHandler';
import { handleEditorTabClick } from './handleEditor';

function handleTabClick(event) {
  const { target } = event;

  updateURL(target);
}

function onTabRegister(mutationList) {
  const tabs = document.querySelectorAll('.pagetab');

  for (const mutation of mutationList) {
    if (mutation.type === 'childList') {
      const newTab = mutation.addedNodes[0];

      newTab.addEventListener('click', (event) =>
        handleEditorTabClick(event, tabs)
      );
    }
  }
}

function watchTabsChanges() {
  const tabsElement = document.querySelector('.Tab');
  if (!tabsElement) return;

  const config = { attributes: true, childList: true, subtree: true };

  const observer = new MutationObserver(onTabRegister);
  observer.observe(tabsElement, config);
}

export { handleTabClick, watchTabsChanges };
