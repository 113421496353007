/* eslint-disable */
import Swiper, { Navigation, Pagination, EffectFade } from 'swiper';
import 'swiper/swiper-bundle.css';

Swiper.use([Navigation, Pagination, EffectFade]);

const loadSwiper = () => {
  const swiperAwards = new Swiper('.awards-carousel-swipper', {
    slidesPerView: 1,
    spaceBetween: 24,
    effect: 'fade',
    fadeEffect: { crossFade: true },
    pagination: {
      el: '.awards-swiper-pagination',
      clickable: true,
      type: 'bullets',
    },
    on: {
      init: function () {
        const current = this.el.closest('.awards-carousel').querySelector('.awards-swiper-pagination-current');
        const total = this.el.closest('.awards-carousel').querySelector('.awards-swiper-pagination-total');
        if (current && total) {
          current.textContent = this.realIndex + 1;
          total.textContent = this.slides.length;
        } else {
          console.log('Fraction pagination elements not found');
        }
      },
      slideChange: function () {
        const current = this.el.closest('.awards-carousel').querySelector('.awards-swiper-pagination-current');
        if (current) {
          current.textContent = this.realIndex + 1;
        } else {
          console.log('Current fraction pagination element not found');
        }
      },
    },
    navigation: {
      nextEl: '.awards-carousel-next',
      prevEl: '.awards-carousel-prev',
    },
  });
};

window.addEventListener('load', loadSwiper);
