/* eslint-disable */
import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';

Swiper.use([Navigation, Pagination]);

const loadSwiperBF = () => {
  const swiper = new Swiper('.cards-image-carousel-bf-swiper', {
    slidesPerView: 1,
    spaceBetween: 30,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      type: 'bullets',
    },
    navigation: {
      nextEl: '.Plans-next',
      prevEl: '.Plans-prev',
    },
    breakpoints: {
      480: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      968: {
        slidesPerView: 3,
      },
    },
  });
};

window.addEventListener('load', loadSwiperBF);
