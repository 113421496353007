/* eslint-disable */
import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.css';

Swiper.use([Navigation, Pagination]);

const loadSwiper = () => {
  const swiperBenefit = new Swiper('.benefit-cards-carousel-swipper', {
    slidesPerView: 1.5,
    spaceBetween: 24,
    draggable: true,
    slideClass: 'benefit-slide',
    allowTouchMove: true,
    pagination: {
      el: '.benefit-cards-swiper-pagination',
      clickable: true,
      type: 'bullets',
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
      },
    },
  });

  function destroySwiper() {
    if (swiperBenefit) {
      swiperBenefit.destroy(true, true);
      swiperBenefit = null;
    }
  }

  function handleResize() {
    if (window.innerWidth < 980) {
      initializeSwiper();
    } else {
      destroySwiper();
    }
  }

  handleResize();

  window.addEventListener('resize', handleResize);
};



window.addEventListener('load', loadSwiper);
