window.addEventListener('load', async () => {
  const form = document.querySelector('.form-lp-bf');
  const btnModal = document.querySelector('.button-modal');
  const modalContainer = document.querySelector('.modal-container');

  // Get city value cookie
  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }
  const planName = decodeURIComponent(getCookie('PlanName'));

  if(btnModal != null) {
    btnModal.addEventListener('click', () => modalContainer.style.display = "none")
  }

  if(form != null) {
    // Change city input value using city cookie
    const inputCidade = form.querySelector('input[name="cidade"]');
    if (inputCidade) {
      inputCidade.value = planName ? planName : "";
    }

    const checkboxes = Array.from(document.querySelectorAll('.checkbox-label:not(.termos)'));
    let checkeds = [];
    checkboxes.map(checkbox => {
      checkbox.addEventListener("click", (e) => {
        if (checkbox.children[0].checked == true) {
          if (!checkeds.includes(checkbox.children[1].innerText)) {
            checkeds.push(checkbox.children[1].innerText)
          }
        } else {
          const index = checkeds.indexOf(checkbox.children[1].innerText);
          if (index > -1) {
              checkeds.splice(index, 1);
          }
        }

        const services = document.querySelector("#cf_servico_mais_interessante");
        if (services) {
          services.value = checkeds.join(", ");
        }
      })
    })

    // Submit form event
    form.addEventListener('submit', (event) => {
      event.preventDefault();
      modalContainer.style.display = "flex";
      dataLayerFormsBF()
    });
  }
})

function dataLayerFormsBF(){
  const formsComp = document.getElementsByClassName('form-lp-bf')[0]
  const inputsComp = formsComp.querySelectorAll('.input')
  const labelsComp = formsComp.querySelectorAll('.checkData')
  const btnSubmitComp = formsComp.getElementsByClassName('generateLeadBtn')[0]

  let product = []
  let idNumber = 0
  for(let i = 0; i < labelsComp.length; i++){
    if(labelsComp[i].querySelectorAll('input')[0].checked){
      idNumber = idNumber + 1
      product.push({
        item_id : 'ID'+ idNumber,
        item_name : labelsComp[i].querySelectorAll('p')[0].innerText
      })
    }
  }
  
  dataLayer.push({
    element_text: "lead_pg_bf",
    eventlead_source: btnSubmitComp.innerText,
    Item: product,
    user_data: {
      nome: inputsComp[0].value,
      email: inputsComp[1].value,
      telefone: inputsComp[2].value,
      cidade: inputsComp[3].value
    }
  });
}
