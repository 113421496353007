/* eslint-disable */
window.addEventListener('load', () => {
  if (document.body.getBoundingClientRect().width < 415) {
    const text = document.querySelector('.AboutUs-card .Typography.text');

    if (text) {
      let fullText = text.innerHTML;
      let words = fullText.split(' ');

      if (words.length > 35) {
        readLess();
      }

      function readLess() {
        text.innerHTML = words.slice(0, 35).join(' ');
        text.innerHTML += `... <button class="about-us-cards-swiper__read-more" onClick="readMore()" /> Leia Mais</button><i class="icon icon-keyboard_arrow_down"></i>`;
      }

      function readMore() {
        text.innerHTML = fullText;
        text.innerHTML += `<button class="about-us-cards-swiper__read-more" onClick="readLess()" /> Leia Mais</button><i class="icon icon-keyboard_arrow_down" style="transform: rotate(180deg); display:inline-block;"></i>`;
      }

      window.readLess = readLess;
      window.readMore = readMore;
    }
  }
});
