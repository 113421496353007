import Common from '../wp-controlers/common-panel/geralAttributes';

export default {
  variant: {
    type: 'string',
    default: 'default',
  },
  imageMobile: {
    type: 'object',
    default: {
      id: '',
      src: '',
      alt: '',
    },
  },
  imageDesk: {
    type: 'object',
    default: {
      id: '',
      src: '',
      alt: '',
    },
  },
  ...Common,
};
