const { PanelBody, PanelRow } = wp.components;
import HideControl from '../hide-control/index.jsx';
import ShowControl from '../show-control/index.jsx';
import MarginControl from '../select-margin/index.jsx';

const CommonPanel = ({ attributes, setAttributes }) => {
  return (
    <PanelBody title="Opções gerais" initialOpen={false}>
      <MarginControl attributes={attributes} setAttributes={setAttributes} />

      <p>
        <strong>Visibilidade:</strong>
        <br />
        Se nenhuma das opções estiverem ativas o bloco aparecerá em todas as
        cidades
      </p>
      <HideControl attributes={attributes} setAttributes={setAttributes} />
      <ShowControl attributes={attributes} setAttributes={setAttributes} />
    </PanelBody>
  );
};

export default CommonPanel;
