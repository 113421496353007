function validateForm() {
  const inputCpfClient = document.querySelector('#field-cpf')
  const inputNameClient = document.querySelector('#field-name')
  const formValidation = document.querySelectorAll(
    '.formCupom__field--validation'
  )
  const submitForm = document.querySelector('.submit-cupom')
  const formCupom = document.querySelector('#formCupom')
  const modalCupom = document.querySelector('.modalCupom')
  const modalCupomClose = document.querySelector('.modalCupom__close')
  const respForm = document.querySelector('.modalCupom p')
  const body = document.querySelector('body')
  const copyCupom = document.querySelector('.copy-cupom')
  const copySuccess = document.querySelector('.copySuccess')
  let cupom = ''

  // Submit form

  if(formCupom != null) {
    formCupom.addEventListener('submit', (e) => {
      e.preventDefault()
  
      const cpf = inputCpfClient.value
  
      const sheetID = '1stGyqEJbBmVeq37H3_Gv99idyviv6xD4IGRrN8T80eM'
      const base = `https://docs.google.com/spreadsheets/d/${sheetID}/gviz/tq?`
      const sheetName = 'cupons'
      const query = encodeURIComponent(`Select D WHERE B = "${cpf}"`)
      const url = `${base}&sheet=${sheetName}&tq=${query}`
  
      fetch(url)
        .then((res) => res.text())
        .then((rep) => {
          const jsData = JSON.parse(rep.substr(47).slice(0, -2))
          const colz = []
          jsData.table.cols.forEach((heading) => {
            if (heading.label) {
              colz.push(heading.label.toLowerCase().replace(/\s/g, ''))
            }
          })
  
          if (jsData.table.rows != '') {
            jsData.table.rows.forEach((main) => {
              const row = {}
              colz.forEach((ele, ind) => {
                row[ele] = main.c[ind] != null ? main.c[ind].v : ''
              })
              cupom = row.cod
              respForm.innerHTML = `O seu cupom é: ${row.cod}`
              modalCupom.classList.add('active')
              copyCupom.classList.remove('copy-cupom-invisible')
              body.classList.add('body-open')
            })
          } else {
            respForm.innerHTML =
              'Verifique se seus dados estão corretos. Caso estejam corretos e seu cupom não seja gerado, envie um e-mail para indique@sumicity.net.br e solicite a inscrição no Programa.'
            modalCupom.classList.add('active')
            copyCupom.classList.add('copy-cupom-invisible')
            copySuccess.classList.add('copySuccess-invisible')
            body.classList.add('body-open')
          }
        })
    })
  }

  //close modal
  if(modalCupomClose != null) {
    modalCupomClose.addEventListener('click', () => {
      modalCupom.classList.remove('active')
      body.classList.remove('body-open')
    })
  }

  //copy cupom
  if(copyCupom != null) {
    copyCupom.addEventListener('click', async function() {
      copySuccess.classList.add('copySuccess-active')
      await navigator.clipboard.writeText(cupom);
    })
  }

  let validation = {
    cpf: false,
    name: false
  }

  if(inputCpfClient != null) {
    inputCpfClient.addEventListener('input', function (e) {
      maskCpf(e, inputCpfClient)
      if (ValidateCpf(e)) {
        validation.cpf = true
        formValidation[1].style.display = 'none'
        validateForm()
      } else {
        validation.cpf = false
        formValidation[1].style.display = 'block'
        validateForm()
      }
    })
  }

  if(inputNameClient != null) {
    inputNameClient.addEventListener('input', function (e) {
      let data = e.target.value

      if (data.length >= 3) {
        validation.name = true
        formValidation[0].style.display = 'none'
        validateForm()
      } else {
        validation.name = false
        formValidation[0].style.display = 'block'
        validateForm()
      }
    })
  }

  function validateForm() {
    if (validation.cpf && validation.name) {
      submitForm.disabled = false
    } else {
      submitForm.disabled = true
    }
  }
}

// Check CPF
function ValidateCpf(e) {
  if (
    /^[0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2}$/.test(e.target.value) &&
    TestaCPF(e.target.value.replace(/[\.-]/g, ''))
  ) {
    return true
  } else {
    return false
  }
}

function TestaCPF(strCPF) {
  var Soma
  var Resto
  Soma = 0
  if (strCPF == '00000000000') return false
  for (i = 1; i <= 9; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i)
  Resto = (Soma * 10) % 11

  if (Resto == 10 || Resto == 11) Resto = 0
  if (Resto != parseInt(strCPF.substring(9, 10))) return false

  Soma = 0
  for (i = 1; i <= 10; i++)
    Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i)
  Resto = (Soma * 10) % 11

  if (Resto == 10 || Resto == 11) Resto = 0
  if (Resto != parseInt(strCPF.substring(10, 11))) return false
  return true
}

// ============= Mask ============

function maskCpf(e, data) {
  data.value = e.target.value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(\-d{2})\d+?$/, '$1')
}

window.addEventListener('DOMContentLoaded', validateForm)
